import { makeAutoObservable } from 'mobx';
import { api } from './api';
import { dlg } from './dlg';
import { geo } from './geoService';
import { NewDlg } from './newDlg';
import { state } from './state';
import { autofocus } from './utils/autofocus';

export enum PostType {
	help_wanted = 1,
	help_offered = 2
}

const PostTypeList = [PostType.help_wanted, PostType.help_offered];

const PostTypeSet = new Set(PostTypeList);

export const PostTypeMap = new Map<PostType, string>();
PostTypeMap.set(PostType.help_offered, 'Help Offered');
PostTypeMap.set(PostType.help_wanted, 'Help Wanted');

function PostingDlg(p: { t: IPosting }) {
	let el: HTMLDivElement;

	const is_new = !p.t.ref;

	const l = makeAutoObservable({ ...p.t });

	async function save() {
		if (!l.name || !l.desc || !l.phone || !l.title) {
			alert('Name, title, description, and phone number are required');
			return;
		}

		if (l.pin?.length != 4) {
			alert('Pin Code must be 4 digits');
			return;
		}

		const thenum = l.phone.replace(/[^0-9]/g, '');
		if (thenum.length < 10) {
			alert('Phone number must have 10 digits');
			return;
		}

		if (geo.info.zip !== l.zip_code) {
			let ginfo = await geo.coordsFromZip(l.zip_code);
			if (!ginfo) {
				alert('invalid zip code');
				return;
			} else {
				l.long = ginfo.lng;
				l.lat = ginfo.lat;
			}
		}

		await api.savePosting(l);
		Object.assign(p.t, l);
		dlg.close(el, p.t);

		if (is_new) {
			const edit_url = document.location.origin + `?id=${l.ref.id}`;
			//window.history.replaceState(null, null, edit_url);
			await dlg.open(NewDlg, { edit_url, pin: l.pin });
		}
	}

	async function deletePosting() {
		if (!confirm('Are you sure you want to delete your posting?')) return;
		await api.deletePosting(p.t);
		dlg.close(el);
		state.refreshList();
	}

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '500px' }}>
				<h4 class="dlg-header nomargin">
					{is_new ? 'Create New Posting' : 'Edit Posting'}
					{state.has_org && (
						<span>
							&nbsp;for <span style={{ color: 'blue' }}>{state.org.name}</span>
						</span>
					)}
				</h4>
				<div class="dlg-content">
					<div class="row">
						<label>Post Type</label>
						<select value={l.type} onChange={(e) => (l.type = parseInt(e.target.value))}>
							<option value={PostType.help_wanted}>Help Wanted</option>
							<option value={PostType.help_offered}>Help Offered</option>
						</select>
					</div>
					<div class="row">
						<label>Zip Code</label>
						<input
							value={l.zip_code}
							onChange={(e) => {
								l.zip_code = e.target.value;
							}}
						/>
					</div>
					<div class="row flex gap20" style={{ 'margin-bottom': 0 }}>
						<div class="row grow">
							<label>Your Name</label>
							<input maxLength={30} value={l.name} onInput={(e) => (l.name = e.target.value)} ref={(e) => [autofocus(e)]} />
						</div>
						<div class="row">
							<label>Reference Name (optional)</label>
							<input maxLength={30} value={l.name_reference} onInput={(e) => (l.name_reference = e.target.value)} />
						</div>
					</div>
					<div class="row">
						<label>Phone Number</label>
						<input maxLength={20} value={l.phone} onInput={(e) => (l.phone = e.target.value)} />
					</div>
					<div class="row">
						<label>Posting Title</label>
						<input maxLength={80} value={l.title} onInput={(e) => (l.title = e.target.value)} />
					</div>
					<div class="row">
						<label>Description</label>
						<textarea maxLength={200} style={{ resize: 'none' }} rows={3} value={l.desc} onInput={(e) => (l.desc = e.target.value)} />
					</div>
					{is_new && (
						<div class="row">
							<label>4 digit pin code (use to edit this posting in the future)</label>
							<input type="number" maxLength={4} value={l.pin} onInput={(e) => (l.pin = e.target.value)} />
						</div>
					)}
				</div>
				<div class="dlg-footer">
					{!is_new && (
						<button class="danger" onclick={deletePosting}>
							Delete
						</button>
					)}
					<span class="grow"></span>
					<button class="primary" onClick={save}>
						{is_new ? 'Create' : 'Save Changes'}
					</button>
					<button onClick={(e) => dlg.close(el)}>Cancel</button>
				</div>
			</div>
		</div>
	);
}

export function openPostingDlg(t?: IPosting): Promise<IPosting> {
	if (!t) {
		t = {
			name: '',
			name_reference: null,
			title: '',
			desc: '',
			phone: '',
			type: PostType.help_wanted,
			post_date: new Date(),
			lat: geo.info.lat,
			long: geo.info.lng,
			updated: new Date(),
			archived: false,
			zip_code: geo.info.zip,
			replies: [],
			pin: null,
			org_ref: state.org?.ref
		};
	}
	return dlg.open(PostingDlg, { t });
}
