import { makeAutoObservable } from 'mobx';
import { dlg } from './dlg';
import { state } from './state';
import { api } from './api';
import { openOrg } from './orgDlg';

export function AdminDlg() {
	let el = null;

	const l = makeAutoObservable({
		orgs: [] as IOrg[],
	});

	api.getAllOrgs().then((r) => (l.orgs = r));

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '500px' }}>
				<div class="dlg-header">
					<b class="grow">Admin Controls</b>
					<a onClick={(e) => dlg.close(el)}>Close</a>
				</div>
				<div class=" dlg-content">
					<h4>Groups</h4>
					{l.orgs.map((o) => (
						<a
							onClick={(e) => {
								openOrg(o);
							}}
						>
							{o.name}
						</a>
					))}

					<h4>Postings</h4>
					{state.list.map((p) => (
						<a
							onClick={(e) => {
								dlg.close(el);
								state.editPost(p.ref.id);
							}}
						>
							{p.title}
						</a>
					))}
				</div>
			</div>
		</div>
	);
}
