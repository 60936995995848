import { css } from 'goober';
import { PostType, PostTypeMap } from './postingDlg';
import { openViewPostingDlg } from './viewPostingDlg';

const c_post_tag = css`
	color: black;
	font-size: 14px;
	padding: 5px;
	border-radius: 5px;
	background: lime;
	margin-right: 5px;
`;

export function PostTag(p: { t: PostType }) {
	return (
		<div class={c_post_tag} style={{ background: p.t === PostType.help_wanted ? 'aqua' : 'lime' }}>
			{PostTypeMap.get(p.t)}
		</div>
	);
}

export function Posting(z: { p: IPosting }) {
	const p = z.p;
	return (
		<div class="posting" onClick={(e) => openViewPostingDlg(p)}>
			<div class="flex center" style={{ color: '#555', 'margin-bottom': '10px' }}>
				<PostTag t={p.type} />
				<div class="grow" style={{ 'font-weight': 'bold' }}>
					{p.title}
				</div>
				<span style={{ 'font-size': '12px', 'text-align': 'right' }}>
					{p.post_date?.toDateString()}
					<br />
					actions:&nbsp;{p.replies.length}
				</span>
			</div>
			<div>{p.desc}</div>
		</div>
	);
}
