import { enableExternalSource, onMount } from 'solid-js';
import { render } from 'solid-js/web';
import { geo } from './geoService';
import { Loading } from './loading';
import { openPostingDlg } from './postingDlg';
import { state } from './state';
import { Reaction, configure, untracked } from 'mobx';
import { Posting } from './posting';
import { dlg } from './dlg';
import { openOrg } from './orgDlg';
import { AdminDlg } from './admin';
import { openLoginDlg } from './loginDlg';
import { openHistory } from './history';

configure({
	enforceActions: 'never'
});

let id = 0;
enableExternalSource((fn, trigger) => {
	const reaction = new Reaction(`externalSource@${++id}`, trigger);
	return {
		track: (x) => {
			let next;
			reaction.track(() => (next = fn(x)));
			return next;
		},
		dispose: () => reaction.dispose()
	};
}, untracked);

function changeZip() {
	const zip = prompt('Enter your zip:');
	if (zip) {
		geo.coordsFromZip(zip).then(() => {
			state.refreshList();
		});
	}
}

function Navbar() {
	return (
		<div id="title">
			<div class="inner flex gap10 center">
				<img src="/icons8-help-50.png" />
				<div style={{ 'font-size': '20px' }}>Bit of Help</div>
				<span style={{ color: 'aqua' }}>- where people help people</span>
				<span class="grow" />
				{state.admin && (
					<a
						style={{ color: 'lime' }}
						onClick={(e) => {
							dlg.open(AdminDlg, {});
						}}
					>
						admin
					</a>
				)}
				<button
					onClick={async (e) => {
						let p = await openPostingDlg(null);
						if (p) state.refreshList();
					}}
				>
					Create Posting
				</button>
				{/* <button
					onClick={(e) => {
						let id = prompt('Enter post id:');
						if (id) {
							state.editPost(id);
						}
					}}
				>
					Edit Posting
				</button> */}
			</div>
		</div>
	);
}

function Postings() {
	return (
		<div id="app_content">
			{state.org && (
				<div style={{ 'margin-bottom': '20px' }}>
					{state.org.msg && <p style={{ 'margin-top': 0 }}>{state.org.msg}</p>}
					<h3 style={{ 'border-bottom': '1px solid' }}>Postings for {state.org.name}</h3>
					{state.org_list.map((p) => (
						<Posting p={p} />
					))}
					{!state.org_list.length && <div>No Postings Found</div>}
				</div>
			)}

			<h3 style={{ 'border-bottom': '1px solid' }}>
				Postings within 100 miles of {geo.info?.city} (<a onClick={changeZip}>{geo.info.zip}</a>)
			</h3>
			{state.list.map((p) => (
				<Posting p={p} />
			))}
		</div>
	);
}

function Footer() {
	return (
		<div class="flex center jcenter gap20" style={{ background: 'gainsboro', padding: '4px' }}>
			{!state.has_user && <a onClick={(e) => openLoginDlg(null)}>Provider Sign In</a>}
			{state.has_user && <a onClick={(e) => openLoginDlg(state.user)}>{state.user.email}</a>}
			{state.has_user && <a onClick={(e) => state.signOut()}>Sign Out</a>}
			{state.has_user && <a onClick={(e) => openHistory()}>History</a>}
			{state.is_org_user && (
				<a
					onClick={(e) => {
						openOrg(state.org);
					}}
				>
					Edit Group
				</a>
			)}
			{state.has_user && !state.has_org && (
				<a
					onClick={(e) => {
						openOrg(null);
					}}
				>
					Create Group
				</a>
			)}
		</div>
	);
}

function App() {
	onMount(() => {
		let mq = window.matchMedia('(max-width: 600px)');
		function onChange() {
			state.mobile = mq.matches;
		}
		mq.onchange = onChange;
		onChange();
	});
	return (
		<div id="app_main">
			<Navbar />
			<Postings />
			<Footer />
			<Loading />
		</div>
	);
}

render(() => <App />, document.getElementById('app'));

addEventListener('unhandledrejection', (event) => {
	alert('UNHANDLED ERROR: ' + event.reason);
});

addEventListener('error', (event) => {
	alert(`UNHANDLED ERROR: ${event.message}`);
});
