import { makeAutoObservable } from 'mobx';
import { api } from './api';
import { geo } from './geoService';
import { openPostingDlg } from './postingDlg';

class State {
	mobile = false;
	admin = false;
	list: IPosting[] = [];
	org_list: IPosting[] = [];
	//edit_ids = new Set<string>();
	org: IOrg = null;
	user: IUser = null;

	constructor() {
		makeAutoObservable(this);
		/*let edit_ids = localStorage.getItem('edit_ids');
		if (edit_ids) {
			for (let id of edit_ids.split('|')) {
				this.edit_ids.add(id);
			}
		}*/
	}

	get has_user() {
		return !!this.user;
	}

	get has_org() {
		return !!this.org;
	}

	get is_org_user() {
		return this.org?.user_emails.includes(this.user?.email);
	}

	signIn(u: IUser) {
		this.user = u;
		localStorage.setItem('user', u.email);
	}

	signOut() {
		this.user = null;
		localStorage.removeItem('user');
	}

	/*add_edit_id(id: string) {
		this.edit_ids.add(id);
		let ids = [...this.edit_ids].join('|');
		localStorage.setItem('edit_ids', ids);
	}*/

	async init() {
		const urlParams = new URLSearchParams(window.location.search);
		const id = urlParams.get('id');
		this.admin = !!urlParams.get('admin');

		if (id) {
			this.editPost(id);
		}

		await geo.locate();

		let email = localStorage.getItem('user');
		if (email) {
			api.getUser(email).then((r) => {
				if (r) {
					this.signIn(r);
				}
			});
		}

		let orgPath = document.location.pathname?.replace('/', '') || null;
		if (orgPath) {
			this.org = await api.getOrg(orgPath);
		}

		this.refreshList();
	}

	async editPost(id: string) {
		const t = await api.getPosting(id);
		if (t) {
			await openPostingDlg(t);
			this.refreshList();
		} else {
			alert('Post not found');
		}
		this.reset_url();
	}

	reset_url() {
		window.history.replaceState(null, null, document.location.origin);
	}

	async refreshList() {
		this.list = await api.getPostingsByGeoLoc();
		if (this.org) {
			this.org_list = await api.getPostingsByOrg(this.org.ref);
			for (let p of this.org_list) {
				let idx = this.list.findIndex((pp) => pp.ref.id === p.ref.id);
				if (idx >= 0) this.list.splice(idx, 1);
			}
		}
	}

	makeid(): string {
		let result = '';
		const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
		const charactersLength = characters.length;
		let counter = 0;
		while (counter < 10) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
			counter += 1;
		}
		return result;
	}
}

export const state: State = new State();
state.init();

//@ts-ignore
window.state = state;

window.addEventListener('error', function (e) {
	alert('Error occurred: ' + e.error.message);
	return false;
});

window.addEventListener('unhandledrejection', function (e) {
	alert('Error occurred: ' + e.reason.message);
});

onerror;
