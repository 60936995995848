import { makeAutoObservable } from 'mobx';
import { api } from './api';
import { dlg } from './dlg';
import { Posting } from './posting';

export function UserPostingsDlg(p: { phone: string }) {
	let el: HTMLDivElement;

	const l = makeAutoObservable({ list: [] as IPosting[] });
	api.getPostingsByPhone(p.phone).then((r) => (l.list = r));

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '500px' }}>
				<h4 class="dlg-header nomargin">Posting by phone number: {p.phone}</h4>
				<div class="dlg-content">
					{l.list.map((p) => (
						<Posting p={p} />
					))}
				</div>
				<div class="dlg-footer">
					<button onClick={(e) => dlg.close(el)}>Close</button>
				</div>
			</div>
		</div>
	);
}
