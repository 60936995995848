// Import the functions you need from the SDKs you need

import { initializeApp } from 'firebase/app';
import {
	getFirestore,
	collection,
	getDocs,
	doc,
	query,
	where,
	setDoc,
	DocumentReference,
	QueryDocumentSnapshot,
	deleteDoc,
	documentId,
	getDoc,
	updateDoc,
	Query
} from 'firebase/firestore';
import { openLoader } from './loading';
import { getRandomChance } from './utils';
import { geo } from './geoService';
import { makeAutoObservable } from 'mobx';
import { PostType } from './postingDlg';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
	apiKey: 'AIzaSyBi2Ux8fmvV1UH9Uw_bxt1u8ap9iv3oAb4',
	authDomain: 'bitofhelp-a8dab.firebaseapp.com',
	projectId: 'bitofhelp-a8dab',
	storageBucket: 'bitofhelp-a8dab.appspot.com',
	messagingSenderId: '93228264454',
	appId: '1:93228264454:web:9ee72183f3750b4260a787'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

function hydrate_obj(o: any) {
	for (let p in o) {
		let v = o[p];
		if (Array.isArray(v)) {
			v.forEach((i) => {
				if (i instanceof Object) hydrate_obj(i);
			});
		} else if (v?.toDate) {
			o[p] = v.toDate();
		}
	}
}

function hydrate(o: any, doc: QueryDocumentSnapshot) {
	if (!o) return;
	o.ref = doc.ref;

	hydrate_obj(o);
}

function getObj<T>(d: QueryDocumentSnapshot): T {
	let o = d.data() as T;
	hydrate(o, d);
	return o;
}

class API {
	constructor() {
		makeAutoObservable(this);
	}

	savePosting(t: IPosting): Promise<void> {
		let is_new = t.ref == undefined;
		if (!t.ref) t.ref = doc(collection(db, 'postings'));

		let data: IPosting = {
			...t,
			post_date: new Date(t.post_date),
			updated: new Date(t.post_date)
		};

		delete data.replies;

		let p = setDoc(t.ref as DocumentReference, data);
		if (is_new) return openLoader(p);
		else p;
	}

	saveAction(t: IReply) {
		let is_new = t.ref == undefined;
		if (!t.ref) t.ref = doc(collection(db, 'actions'));

		let data: IReply = {
			...t,
			reply_date: new Date(t.reply_date)
		};

		let p = setDoc(t.ref as DocumentReference, data);
		if (is_new) return openLoader(p);
		else p;
	}

	async getActions(f: IActionFilter): Promise<IReply[]> {
		let q = query(collection(db, 'actions'));
		if (f.posting_refs) {
			q = query(q, where('posting_ref', 'in', f.posting_refs));
		} else if (f.user_ref) {
			q = query(q, where('user_ref', '==', f.user_ref));
		} else if (f.org_ref) {
			q = query(q, where('org_ref', '==', f.org_ref));
		} else return [];

		const docs = await getDocs(q);
		return docs.docs.map((d) => getObj(d));
	}

	async getPosting(id: string): Promise<IPosting> {
		const ref = doc(db, 'postings/' + id);
		let t = await getDoc(ref);
		let tt: IPosting = getObj(t);
		this._hydratePosting(tt);
		return tt;
	}

	async getPostingsByPhone(phone: string): Promise<IPosting[]> {
		let q = query(collection(db, 'postings'));
		q = query(q, where('phone', '==', phone));
		return this._getPostings(q, true);
	}

	async getPostingsByOrg(org_ref: IReference): Promise<IPosting[]> {
		let q = query(collection(db, 'postings'));
		q = query(q, where('org_ref', '==', org_ref));
		return this._getPostings(q, true);
	}

	async getPostingsByRef(refs: IReference[]): Promise<IPosting[]> {
		let q = query(collection(db, 'postings'));
		q = query(
			q,
			where(
				documentId(),
				'in',
				refs.map((r) => r.id)
			)
		);
		return this._getPostings(q, false);
	}

	async getPostingsByGeoLoc(miles: number = 100): Promise<IPosting[]> {
		if (!geo.info.lat || !geo.info.lng) return [];
		// get postings within 100miles
		const MILES_PER_DEG = 69;
		const RADIUS_DEG = miles / MILES_PER_DEG;

		const lat_min = geo.info.lat - RADIUS_DEG;
		const lat_max = geo.info.lat + RADIUS_DEG;
		const long_min = geo.info.lng - RADIUS_DEG;
		const long_max = geo.info.lng + RADIUS_DEG;

		let q = query(collection(db, 'postings'));
		q = query(q, where('lat', '>', lat_min));
		q = query(q, where('lat', '<', lat_max));
		q = query(q, where('long', '>', long_min));
		q = query(q, where('long', '<', long_max));
		const r = await this._getPostings(q, true);
		return r;
	}

	async _getPostings(q: Query, include_actions: boolean) {
		const r = await getDocs(q);
		const rr = r.docs.map((d) => getObj<IPosting>(d));

		let actions: IReply[];
		let posting_refs = rr.map((p) => p.ref);
		if (include_actions && posting_refs.length) {
			actions = await this.getActions({ posting_refs });
		}
		rr.forEach((p) => {
			this._hydratePosting(p);
			p.replies = actions?.filter((a) => a.posting_ref.path == p.ref.path) || [];
		});
		return rr;
	}

	async getAllOrgs(): Promise<IOrg[]> {
		let q = query(collection(db, 'orgs'));
		const r = await getDocs(q);
		const rr = r.docs.map((d) => getObj<IOrg>(d));
		return rr;
	}

	async getOrg(path: string): Promise<IOrg> {
		let q = query(collection(db, 'orgs'));
		q = query(q, where('path', '==', path));
		const r = await getDocs(q);
		const rr = r.docs.map((d) => getObj<IOrg>(d));
		const o = rr[0] || null;
		if (o) {
			if (!o.user_emails) o.user_emails = [];
		}
		return o;
	}

	saveOrg(o: IOrg): Promise<void> {
		let is_new = o.ref == undefined;
		if (!o.ref) o.ref = doc(collection(db, 'orgs'));

		let p = setDoc(o.ref as DocumentReference, { ...o });
		if (is_new) return openLoader(p);
		return p;
	}

	saveUser(o: IUser): Promise<void> {
		let is_new = o.ref == undefined;
		if (!o.ref) o.ref = doc(collection(db, 'users'));

		let p = setDoc(o.ref as DocumentReference, { ...o });
		if (is_new) return openLoader(p);
	}

	async getUser(email: string): Promise<IUser> {
		let q = query(collection(db, 'users'));
		q = query(q, where('email', '==', email));
		const r = await getDocs(q);
		const rr = r.docs.map((d) => getObj<IUser>(d));
		return rr[0] || null;
	}

	async getUsersByEmail(emails: string[]): Promise<IUser[]> {
		let q = query(collection(db, 'users'));
		q = query(q, where('email', 'in', emails));
		const r = await getDocs(q);
		const rr = r.docs.map((d) => getObj<IUser>(d));
		return rr;
	}

	_hydratePosting(p: IPosting) {
		if (!p) return;
		if (!p.type) p.type = PostType.help_wanted;
		if (!p.replies) p.replies = [];
		if (!p.zip_code) p.zip_code = null;
	}

	/*async deleteOldPostings() {
		const DAYS_OLD = 180;
		let q = query(collection(db, 'postings'));
		let post_date = new Date();
		post_date.setDate(post_date.getDate() - DAYS_OLD);
		q = query(q, where('post_date', '<', post_date));
		const docs = await getDocs(q);
		docs.forEach((d) => {
			deleteDoc(d.ref);
		});
		console.info(`${docs.size} postings deleted`);
	}*/

	deletePosting(p: IPosting) {
		return deleteDoc(p.ref as DocumentReference);
	}
}
export const api = new API();

//@ts-ignore
window.api = api;

// 5% chance to delete old documents (avg 1 out of 20 users)
/*if (getRandomChance(0.05)) {
	api.deleteOldPostings();
}*/
