import { makeAutoObservable } from 'mobx';
import { dlg } from './dlg';
import { onMount } from 'solid-js';
import { state } from './state';
import { api } from './api';
import { removeFromArray } from './utils';
import { autofocus } from './utils/autofocus';

function ReplyDlg(p: { post: IPosting; reply: IReply; isNew: boolean }) {
	let el: HTMLDivElement;
	const l = makeAutoObservable({ ...p.reply });

	async function save() {
		if (!l.info) {
			alert('Info is required');
			return;
		}

		Object.assign(p.reply, l);
		if (p.isNew) {
			p.post.replies.push(p.reply);
			//state.add_edit_id(p.reply.id);
		}
		await api.saveAction(p.reply);
		dlg.close(el, p.reply);
	}

	async function deletePosting() {
		if (!confirm('Are you sure you want to delete your posting?')) return;
		removeFromArray(p.reply, p.post.replies);
		await api.savePosting(p.post);
		dlg.close(el);
	}

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '600px' }}>
				<h4 class="dlg-header nomargin">Action</h4>
				<div class="dlg-content" style={{ 'flex-grow': 1 }}>
					<div class="row">
						<label>Your Name (optional)</label>
						<input ref={(e) => [autofocus(e)]} value={l.user} onInput={(e) => (l.user = e.target.value)} />
					</div>
					<div class="row">
						<label>Action Type</label>
						<select value={l.type} onChange={(e) => (l.type = e.target.value)}>
							<option>Payment</option>
							<option>Advice or Information</option>
							<option>Food/Supplies</option>
							<option>Donation</option>
							<option>Transportation</option>
							<option>Other</option>
						</select>
					</div>
					<div class="row">
						<label>Amount (optional)</label>
						<input type="number" step={1} value={l.amount} onInput={(e) => (l.amount = parseFloat(e.target.value))} />
					</div>
					<div class="row">
						<label>Notes</label>
						<textarea style={{ height: '150px', resize: 'none' }} value={l.info} onInput={(e) => (l.info = e.target.value)} />
					</div>
				</div>
				<div class="dlg-footer">
					{!p.isNew && (
						<button class="danger" onclick={deletePosting}>
							Delete
						</button>
					)}
					<span class="grow"></span>
					<button class="primary" onClick={save}>
						Save
					</button>
					<button onClick={(e) => dlg.close(el)}>Cancel</button>
				</div>
			</div>
		</div>
	);
}

export function openReplyDlg(post: IPosting, reply: IReply, isNew: boolean): Promise<IReply> {
	if (!reply) {
		reply = {
			posting_ref: post.ref,
			info: '',
			type: '',
			user: state.user?.name,
			amount: null,
			reply_date: new Date(),
			user_ref: state.user.ref,
			org_ref: state.is_org_user ? state.org.ref : null
		};
	}
	return dlg.open(ReplyDlg, { post, reply, isNew });
}
