import { makeAutoObservable } from 'mobx';

interface IIpApiResult {
	city: 'Klamath Falls';
	country: 'United States';
	countryCode: 'US';
	isp?: 'Charter Communications';
	lat: 42.2957;
	lon: -121.8326;
	org?: 'Spectrum';
	query?: '47.36.174.245';
	region?: 'OR';
	regionName?: 'Oregon';
	status?: 'success';
	timezone?: 'America/Los_Angeles';
	zip: '97601';
}

interface IIpGeoResult {
	city: string;
	zipcode: string;
	latitude: string;
	longitude: string;
}

interface IGeoInfo {
	lat: number;
	lng: number;
	city: string;
	zip: string;
}

interface IGmapsResult {
	results: [
		{
			geometry: {
				location: { lat: number; lng: number };
			};
			formatted_address: string;
		}
	];
}

class GeoService {
	info: IGeoInfo = null;

	constructor() {
		makeAutoObservable(this);
		let info = localStorage.getItem('geo-info');
		if (info) {
			this.info = JSON.parse(info);
		}
	}

	setInfo(info: IGeoInfo) {
		this.info = info;
		localStorage.setItem('geo-info', JSON.stringify(info));
	}

	/*locate(): Promise<IGeoInfo> {
		return fetch('http://ip-api.com/json/?fields=city,lat,lon,zip').then((r) =>
			r.json().then((r: IIpApiResult) => {
				this.info = {
					city: r.city,
					zip: r.zip,
					lat: r.lat,
					lng: r.lon
				};
				return this.info;
			})
		);
	}*/

	locate(): Promise<IGeoInfo> {
		if (this.info) return;
		const url = 'https://api.ipgeolocation.io/ipgeo?apiKey=fb073f6f50864cfaaff31fc266fb4c7d';
		return fetch(url).then((r) =>
			r.json().then((r: IIpGeoResult) => {
				this.setInfo({
					city: r.city,
					zip: r.zipcode,
					lat: parseFloat(r.latitude),
					lng: parseFloat(r.longitude)
				});
				return this.info;
			})
		);
	}

	coordsFromZip(zip: string, set: boolean = true): Promise<IGeoInfo> {
		const api_key = 'AIzaSyA4ah7FaA7GlvUrz-DCpzhbIZmQynM3SuI';
		let url = `https://maps.googleapis.com/maps/api/geocode/json?key=${api_key}&components=postal_code:${zip}`;
		return fetch(url).then((r) =>
			r.json().then((r: IGmapsResult) => {
				if (!r.results.length) {
					return null;
				}
				const rr: IGeoInfo = {
					city: r.results[0].formatted_address,
					zip,
					lat: r.results[0].geometry.location.lat,
					lng: r.results[0].geometry.location.lng
				};
				if (set) {
					this.setInfo(rr);
				}
				return rr;
			})
		);
	}
}

export const geo = new GeoService();

//@ts-ignore
window.geo = geo;
