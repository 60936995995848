import { dlg } from './dlg';

export function NewDlg(p: { edit_url: string; pin: string }) {
	let el: HTMLDivElement;

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '500px' }}>
				<h4 class="dlg-header nomargin" style={{ color: 'green' }}>
					Posting was created successfully
				</h4>
				<div class="dlg-content">
					<p>
						To edit your posting, use your Pin Code: <span style={{ background: 'aqua', padding: '5px' }}>{p.pin}</span>
					</p>
					{/* <p style={{ 'margin-top': 0, color: 'darkviolet' }}>Bookmark this address for future access.</p> */}
					{/* <div class="row">
						<input readonly value={p.edit_url} style={{ 'font-size': '12px' }} />
					</div> */}
				</div>
				<div class="dlg-footer">
					<button onClick={(e) => dlg.close(el)}>OK</button>
				</div>
			</div>
		</div>
	);
}
