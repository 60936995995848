import { makeAutoObservable } from 'mobx';
import { api } from './api';
import { dlg } from './dlg';
import { autofocus } from './utils/autofocus';
import { removeFromArray } from './utils';
import { state } from './state';

const valid_org_path = 'abcdefghijklmnopqrstuvwxyz';

function OrgDlg(p: { o: IOrg }) {
	let el: HTMLDivElement;
	const o: IOrg = makeAutoObservable({ ...p.o });

	const l = makeAutoObservable({ user_map: new Map<string, IUser>() });
	api.getUsersByEmail(o.user_emails).then((r) => {
		for (let u of r) l.user_map.set(u.email, u);
	});

	async function save() {
		if (!o.name || !o.path || o.user_emails.length === 0) {
			alert('Name, Path, and at least one member are required');
			return;
		}

		for (let i = 0; i < o.path.length; i++) {
			let c = o.path[i];
			if (!valid_org_path.includes(c)) {
				alert('Path must only contain lowercase characaters a-z');
				return;
			}
		}

		await api.saveOrg(o);
		window.location.href = getUrl();
	}

	function getUrl() {
		let url = location.protocol + '//' + location.host + '/' + o.path;
		return url;
	}

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '500px' }}>
				<div class="dlg-header">Create Group</div>
				<div class="dlg-content">
					<div class="row">
						<label>Name</label>
						<input value={o.name} onChange={(e) => (o.name = e.target.value)} ref={(e) => [autofocus(e)]} />
					</div>
					<div class="row">
						<label>Path (lowercase a - z)</label>
						<input value={o.path} onInput={(e) => (o.path = e.target.value)} />

						{o.path && (
							<input
								style={{
									'font-size': '14px',
									color: 'blue',
									background: 0,
									border: 0
								}}
								readonly
								value={getUrl()}
							/>
						)}
					</div>
					<div class="row">
						<label>Message (optional)</label>
						<textarea value={o.msg} rows={3} onChange={(e) => (o.msg = e.target.value)}></textarea>
					</div>
					<div class="row">
						<div class="flex">
							<label class="rowlabel grow">Members of Group</label>
							<a
								onClick={(e) => {
									let email = prompt('Enter user email');
									if (email) {
										email = email.toLowerCase().trim();
										o.user_emails.push(email);
									}
								}}
							>
								Add User Email
							</a>
						</div>
						<table style={{ width: '100%', border: '1px dashed' }}>
							<tbody>
								{o.user_emails.map((e) => (
									<tr>
										<td>{e}</td>
										<td>{l.user_map.get(e)?.name}</td>
										<td>{l.user_map.get(e)?.phone}</td>
										<td>
											<a
												onclick={(ee) => {
													if (confirm('Are you sure you want to remove this member?')) {
														removeFromArray(e, o.user_emails);
													}
												}}
												class="danger"
											>
												Remove
											</a>
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
				<div class="dlg-footer">
					<button class="primary" onClick={save}>
						Save
					</button>
					<button onClick={(e) => dlg.close(el)}>Cancel</button>
				</div>
			</div>
		</div>
	);
}

export function openOrg(o: IOrg) {
	if (!o) {
		o = {
			name: '',
			path: '',
			msg: null,
			user_emails: [state.user.email]
		};
	}
	dlg.open(OrgDlg, { o });
}
