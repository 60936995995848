import { makeAutoObservable } from 'mobx';
import { dlg } from './dlg';
import { api } from './api';
import { state } from './state';
import { openReplyDlg } from './replyDlg';
import { css } from 'goober';
import { For } from 'solid-js';
import { openViewPostingDlg } from './viewPostingDlg';

const c_table = css`
	th {
		text-decoration: underline;
		color: #b22222;
		font-weight: 400;
		background: none;
	}
`;

interface IHistory {
	r: IReply;
	p: IPosting;
	u: IUser;
}

function HistoryDlg(p: {}) {
	let el: HTMLDivElement;

	let l = makeAutoObservable({
		history: [] as IHistory[],
		total: 0
	});

	if (state.has_user) {
		api.getActions({
			user_ref: state.user.ref
		}).then((r) => hydrateHistory(r));
	}

	/*if (state.has_org) {
		api.getActions({
			org_ref: state.org.ref
		}).then((r) =>
			r.forEach((a) => {
				l.history.push({ r: a, u: null, p: null });
			})
		);
	}*/

	const org_filter = false;

	async function hydrateHistory(rlist: IReply[]) {
		let refs = rlist.map((r) => r.posting_ref);
		let postings = await api.getPostingsByRef(refs);

		for (let r of rlist) {
			if (r.amount) l.total += r.amount;
			let p = postings.find((p) => p.ref.id === r.posting_ref.id);
			l.history.push({ r, u: null, p });
		}
	}

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '800px' }}>
				<h4 class="dlg-header nomargin">History</h4>
				<div class="dlg-content">
					<table class={c_table}>
						<thead>
							<tr>
								<th>Date</th>
								<th>Type</th>
								<th>Amount</th>
								<th>Info</th>
								<th>Posting</th>
								<th>Client</th>
								{org_filter && <th>User</th>}
								<th></th>
							</tr>
						</thead>
						<tbody>
							<For
								each={l.history}
								fallback={
									<tr>
										<td>No Records Found</td>
									</tr>
								}
							>
								{(h) => (
									<tr>
										<td>{h.r.reply_date.toLocaleDateString()}</td>
										<td>{h.r.type}</td>
										<td>{h.r.amount}</td>
										<td>{h.r.info}</td>
										<td>{h.p?.title}</td>
										<td>{h.p?.name}</td>
										{org_filter && <td>{h.u?.name}</td>}
										<td>
											<a onClick={(e) => openViewPostingDlg(h.p)}>View Post</a>
										</td>
									</tr>
								)}
							</For>
						</tbody>
					</table>
				</div>
				<div class="dlg-footer">
					<span class="grow">Total: ${l.total.toFixed(2)}</span>
					<button onClick={(e) => dlg.close(el)}>Print</button>
					<button onClick={(e) => dlg.close(el)}>Download (csv)</button>
					<button onClick={(e) => dlg.close(el)}>Close</button>
				</div>
			</div>
		</div>
	);
}

export function openHistory() {
	dlg.open(HistoryDlg, {});
}
