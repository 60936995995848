import { Accessor, onMount } from 'solid-js';

export const autofocus = (element: HTMLElement, autofocus?: Accessor<boolean>) => {
	if (autofocus?.() === false) {
		return;
	}

	onMount(() => {
		// Using a timeout makes it consistent
		setTimeout(() => element.focus());
	});
};
