import { makeAutoObservable } from 'mobx';
import { api } from './api';
import { dlg } from './dlg';
import { autofocus } from './utils/autofocus';
import { state } from './state';

function LoginDlg(p: { user: IUser }) {
	let el: HTMLDivElement;
	const u: IUser = makeAutoObservable({ ...p.user });
	let l = makeAutoObservable({ new_user: !!p.user.ref, login: !p.user.ref });

	async function save() {
		if (!u.email) {
			alert('Email is required');
			return;
		}

		await api.saveUser(u);
		state.signIn(u);
		dlg.close(el);
	}

	async function signIn() {
		if (u.email?.length < 3) {
			alert('Enter a valid email address.');
			return;
		}
		const found = await api.getUser(u.email);
		if (found) {
			state.signIn(found);
			dlg.close(el);
		} else {
			let r = confirm('Email was not found, would you like to create a new account?');
			if (r) {
				l.new_user = true;
			}
		}
	}

	return (
		<div class="dlgbg" ref={el}>
			<div class="dlg" style={{ width: '500px' }}>
				<div class="dlg-header">{l.login ? 'Sign In' : 'Edit Account'}</div>
				<div class="dlg-content">
					<div class="row">
						<label>Email</label>
						<input
							value={u.email}
							onKeyPress={(e) => {
								if (l.login && e.key === 'Enter') signIn();
							}}
							onInput={(e) => {
								u.email = e.target.value;
								u.email = u.email.toLowerCase().trim();
							}}
							ref={(e) => [autofocus(e)]}
						/>
					</div>
					{l.new_user && (
						<div class="row">
							<label>Name</label>
							<input value={u.name} onChange={(e) => (u.name = e.target.value)} />
						</div>
					)}

					{l.new_user && (
						<div class="row">
							<label>Phone</label>
							<input value={u.phone} onChange={(e) => (u.phone = e.target.value)} />
						</div>
					)}
					{l.new_user && (
						<div class="row">
							<label>
								Active <input type="checkbox" checked={u.active} onchange={(e) => (u.active = !u.active)} />
							</label>
						</div>
					)}
				</div>
				<div class="dlg-footer">
					{l.new_user || !l.login ? (
						<button class="primary" onClick={save}>
							{l.login ? 'Create Account' : 'Save'}
						</button>
					) : (
						<button class="primary" onClick={signIn}>
							Sign In
						</button>
					)}
					<button onClick={(e) => dlg.close(el)}>Cancel</button>
				</div>
			</div>
		</div>
	);
}

export function openLoginDlg(user: IUser) {
	if (!user) {
		user = {
			name: '',
			email: '',
			phone: null,
			active: true
		};
	}
	return dlg.open(LoginDlg, { user });
}
