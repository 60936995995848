export function removeFromArray<T>(item: T, arr: T[]) {
	arr.splice(arr.indexOf(item), 1);
}

export function getRandomInt(min, max): number {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function getRandomItem<T>(arg: T[]): T {
	if (arg.length == 0) return null;
	return arg[getRandomInt(0, arg.length - 1)];
}

export function getRandomChance(chance: number): boolean {
	if (chance <= 0 || chance >= 1) {
		throw new Error('Invalid RandomChance parameter value');
	}

	return Math.random() <= chance;
}

export function parseISOLocal(input: HTMLDataElement): Date {
	let s = input.value;
	var b = s.split(/\D/);
	return new Date(`${b[1]}-${b[2]}-${b[0]}`);
	//return new Date(b[0], b[1] - 1, b[2], b[3], b[4], b[5]);
}

export function dateInputValue(d: Date): string {
	//if (d) return d.toISOString().substring(0, 10);
	let m: any = d.getMonth() + 1;
	if (m < 10) m = '0' + m;

	let date: any = d.getDate();
	if (date < 10) date = '0' + date;
	if (d) return `${d.getFullYear()}-${m}-${date}`;
}

export function formatDate(d: Date) {
	if (!d) return '';
	return d.toLocaleDateString();
}

export function shuffleArray(a: any[]) {
	for (let i = a.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[a[i], a[j]] = [a[j], a[i]];
	}
}
